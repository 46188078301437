import { useNavigate } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useSearchParams } from "react-router-dom";
// import {
  // useQueryClient,
  // keepPreviousData,
  // useQuery,
// } from "@tanstack/react-query";

export default function NumberOfRowsSelect({
  numberOfRows,
}: {
  numberOfRows: number | string;
}) {
  // const queryClient = useQueryClient();
  // const cache = queryClient.getQueryCache();
  // const keys = cache.getAll();
  // console.log("keys01:", keys);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onNumberOfRowsChange = (e: string) => {
    // console.log("change rows");
    searchParams.set("rows", e.toString());

    // This is not ideal, but it's not terrible either
    searchParams.set("p", String(1));

    // console.log("keys02:", keys);
    // queryClient.invalidateQueries({ queryKey: [searchParams] });
    // navigate(`?rows=5&p=1`);
    // console.log("keys03:", keys);
    navigate(`?${searchParams.toString()}`);
  };
  return (
    <Select onValueChange={(e) => onNumberOfRowsChange(e)}>
      <SelectTrigger className="w-[200px]">
        <SelectValue placeholder={numberOfRows} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value="5">5</SelectItem>
        <SelectItem value="10">10</SelectItem>
        <SelectItem value="20">20</SelectItem>
        <SelectItem value="50">50</SelectItem>
      </SelectContent>
    </Select>
  );
}
