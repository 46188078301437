export type ParamsEntries = IterableIterator<Entry> | Array<Entry>;
export type Entry = [string, string];

export const paramsToObject = (
  params: ParamsEntries,
): Record<string, string> => {
  const result: Record<string, string> = {};
  for (const [key, value] of params) {
    result[key] = value;
  }
  return result;
};
