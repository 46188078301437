type Payment = {
  id: string;
  amount: number;
  status: 'pending' | 'processing' | 'success' | 'failed';
  email: string;
};

export const payments: Payment[] = [
  {
    id: '728ed52f',
    amount: 100,
    status: 'pending',
    email: 'm@example.com',
  },
  {
    id: '489e1d42',
    amount: 125,
    status: 'processing',
    email: 'example@gmail.com',
  },
  // ...
];

export type TableHeader = {
  slug: string;
  label: string;
  width?: string
  size?: string
}[];

const idColSize = '30px'

export const purchaseTableHeaders: TableHeader = [
  { slug: 'id', label: 'ID', size: idColSize  },
  { slug: 'itemName', label: 'Name', size: '2fr'  },
  { slug: 'datePurchased', label: 'Date', size: '1fr'  },
  { slug: 'source', label: 'Source', size: '1fr'  },
  // { slug: 'multipleItems', label: 'Multiple Items', size: '1fr'  },
  // { slug: 'isListed', label: 'Listed', size: '1fr'  },
  { slug: 'isSold', label: 'Sold', size: '1fr'  },
  { slug: 'purchasePrice', label: 'Cost', size: '1fr'  },
  { slug: 'soldTotals', label: 'Earned', size: '1fr'  },
  { slug: 'earningsPercent', label: 'Return', size: '1fr' },
  { slug: 'actions', label: " ", size: '1fr' }
];

export const purchaseTableMobileHeaders: TableHeader = [
  { slug: 'id', label: 'ID', size: idColSize  },
  { slug: 'itemName', label: 'Name', size: '2fr'  },
  { slug: 'datePurchased', label: 'Date', size: '1fr'  },
  // { slug: 'purchasePrice', label: 'Cost', size: '1fr'  },
  // { slug: 'soldTotals', label: 'Sales', size: '1fr'  },
  // { slug: 'earningsPercent', label: '%', size: '1fr' }
]

export const purchaseTableItemHeaders: TableHeader = [
  { slug: 'name', label: 'Name', size: '2fr' },
  { slug: 'listedSource', label: 'Listed On', size: '1fr' },
  // { slug: 'soldPriceTotal', label: 'Sold Price' },
  { slug: 'soldDate', label: 'Sold Date', size: '1fr' },
  // { slug: }
  { slug: 'itemNotes', label: 'Notes', size: '1fr' },
  { slug: "actions", label: " ", size: '.5fr'}
];

export const listingTableHeaders: TableHeader = [
  { slug: 'id', label: 'ID', size: idColSize },
  { slug: 'listingName', label: 'Name', size: '2fr' },
  { slug: 'listingDate', label: 'Listed', size: '1fr' },
  { slug: 'listingSold', label: 'Sold', size: '1fr' },
  { slug: 'listingSource', label: 'Source', size: '1fr' },
  // { slug: 'listingCost', label: 'Cost' },
  { slug: 'listingSoldPrice', label: 'Amount', size: '1fr' },
  { slug: "actions", label: " ", size: '.5fr' }
];

export const listingTableMobileHeaders: TableHeader = [
  { slug: 'id', label: 'ID', size: idColSize },
  { slug: 'listingName', label: 'Name', size: '2fr' },
  // { slug: 'listingDate', label: 'Date Listed', size: '1fr' },
  { slug: 'listingSold', label: 'Sold', size: '1fr' },
  // { slug: 'listingSource', label: 'Source', size: '1fr'  },
  // { slug: 'listingCost', label: 'Cost', size: '1fr'  },
  // { slug: 'listingSoldPrice', label: 'Sold Price', size: '1fr'  },
];

export const listingTableItemHeaders: TableHeader = [
  { slug: 'itemName', label: 'Name', size: '2fr' },
  { slug: 'purchaseId', label: 'Purchase(s)', size: '1fr'},
  { slug: 'itemNotes', label: 'Notes', size: '1fr' },
  // { slug: 'actions', label: "actions"}
];

export const itemTableHeaders: TableHeader = [
  { slug: 'id', label: 'ID', size: idColSize  },
  { slug: 'itemName', label: 'Name', size: '2fr'  },
  { slug: 'itemIsListed', label: 'Listed', size: '1fr'  },
  { slug: 'itemIsSold', label: 'Sold', size: '1fr'  },
  // { slug: 'soldPriceTotal', label: 'Sold Price' },
];

